export const gender = [ 'male', 'female', 'unisex', 'kids' ];
export const type = [
  'sneakers', 'shoes',  'boots', 'sandals', 'flipflops', 'slides', 'football boots', 'gear', 'clothes'
];
export const options = [ 0, 2000, 5000, 10000, 20000, 100000 ];

export const brandsFilters = [
  'adidas', 'nike', 'ellesse',
  'diadora', 'new balance', 'rang',
  'reebok', 'lotto', 'puma', 'arena',
  'under armour', 'converse', 'champion', 'rider',
  'umbro', 'asics', 'hummel', 'kappa', 'guess', 'skechers',
  'fila', 'slazenger', 'dockers', 'caterpillar',
  'spalding', 'navigare', 'hoka', 'ipanema', 'crocs',
  'speedo', 'colmar', 'timberland', 'lonsdale',
  'head', 'emporio armani', 'brooks', 'peak', 'carrera',
  'sergio tacchini', 'superdry', 'ugg', 'tommy hilfiger',
  'lacoste', 'vans', 'planika', 'tom tailor',
  'russell athletic', 'ice peak', 'staff jeans', '4f', 'copperminer',
  'jack wolfskin', 'new era', 'joma', 'mizuno', 'shooter'
];

export const sports = [
  'football', 'basketball', 'handball', 'volleyball', 'lifestyle', 'swimming', 'running', 'tennis', 'outdoor', 'fitness', 'skiing', 'other'
];
