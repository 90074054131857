import { Component, OnInit, Input } from '@angular/core';
import { FiltersService } from 'src/app/services/filters.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-carousel-item',
  templateUrl: './carousel-item.component.html',
  styleUrls: ['./carousel-item.component.css']
})
export class CarouselItemComponent implements OnInit {
  @Input() item: any;
  activeFilters;
  constructor(private filtersService: FiltersService, public router: Router) { }

  ngOnInit() {
    this.activeFilters = this.filtersService.getFromLocalStorage('brand');
  }

  setFilter() {
    if (!this.activeFilters.includes(this.item.name)) {
    this.filtersService.storeOnLocalStorage(this.item.name, 'brand');
  }
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
    this.router.navigate(['products/all', 1]));
  }

}
